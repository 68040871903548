

<!-- <div *ngIf="!isPrint" class="container pt-0 pb-0">
    <div class="row">
        <div class="col-12 text-end">
            <button class="btn btn-secondary ms-3 text-white" (click)="printPage();"><i class="bi bi-printer text-white"></i></button>

            <button class="btn btn-secondary ms-3 text-white" (click)="downloadPdf();"><i class="bi bi-file-earmark-pdf text-white"></i></button>

        </div>
    </div>
</div> -->


<div  id="exportpdf">
<div class="container">

    <div class="row">
        <div class="col-12 text-center">
            <br><br><br><br>
            <h3><b><u>PROFORMA PO DETAIL</u></b></h3>
        </div>

        <div class="col-12"><br><br></div>

        <div class="col-3 text-end">
            <div *ngIf="companyBase64">
                <img src="{{companyBase64}}" style="max-width: 80; max-height:80px;">
            </div>
            <div *ngIf="!companyBase64">
                <svg width="120" height="120">
                    <rect width="120" height="120" 
                    style="fill:rgb(217,217,217);stroke-width:0;stroke:rgb(0,0,0)" />
                  Sorry, your browser does not support inline SVG.
                </svg>
            </div>
            <!-- <svg width="120" height="120">
                <rect width="120" height="120" 
                style="fill:rgb(217,217,217);stroke-width:0;stroke:rgb(0,0,0)" />
              Sorry, your browser does not support inline SVG.
            </svg> -->
        </div>
        <div class="col-4">
            <b>{{datapoDetail?.procurementDetailAddress?.companyName}}</b><br>
            {{ (datapoDetail?.procurementDetailAddress?.companyMainStreet)?datapoDetail?.procurementDetailAddress?.companyMainStreet:'' }}{{ (datapoDetail?.procurementDetailAddress?.companyMainCity)?', '+datapoDetail?.procurementDetailAddress?.companyMainCity:'' }}{{ (datapoDetail?.procurementDetailAddress?.companyMainCountry)?', '+datapoDetail?.procurementDetailAddress?.companyMainCountry:'' }}{{ (datapoDetail?.procurementDetailAddress?.companyMainPostalCode)?', '+datapoDetail?.procurementDetailAddress?.companyMainPostalCode:'' }}
            <br>

        </div>
        <div class="col-1">

        </div>
        <div class="col-4">
            Phone : {{ (datapoDetail?.procurementDetailAddress?.companyMainPhone)?datapoDetail?.procurementDetailAddress?.companyMainPhone:'-' }}<br>
            Email : {{ (datapoDetail?.procurementDetailAddress?.companyMainEmail)?datapoDetail?.procurementDetailAddress?.companyMainEmail:'-' }}
        </div>

        <div class="col-12"><br></div>

        <div class="col-5">
            <div class="row g-0">
                <div class="col-6 p-2">
                    Payment Term
                </div>
                <div class="col-6 p-2" style="background-color: #f1f1f1;">
                    {{(datapoDetail?.quotationPaymentTermInDays) ? datapoDetail?.quotationPaymentTermInDays+' Days':'-'}}
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5 p-2">
            <div class="row g-0">
                <div class="col-12 ps-2 pe-2 text-end">
                    <b><u>PO Id : # {{formatDisplayId(datapoDetail?.poNumber)}}</u></b>
                </div>
            </div>
            
        </div>

        <div class="col-12 p-1"></div>

        <div class="col-5">
            <div class="row g-0">
                <div class="col-6 p-2">
                    currency
                </div>
                <div class="col-6 p-2" style="background-color: #f1f1f1;">
                    {{ datapoDetail?.rfqCurrency }}
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
            <div class="row g-0">
                <div class="col-6 p-2">
                    Date
                </div>
                <div class="col-6 p-2" style="background-color: #f1f1f1;">
                    {{datapoDetail?.approvedDate | date:'d MMM y'}}
                </div>
            </div>
        </div>

        <div class="col-12 p-1"></div>

        <div class="col-5">
            <div class="row g-0">
                <div class="col-6 p-2">
                    Procurement Internal Ref. No.
                </div>
                <div class="col-6 p-2" style="background-color: #f1f1f1;">
                    {{ (datapoDetail?.vendorRefNumber)?datapoDetail?.vendorRefNumber:'-' }}
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
            <div class="row g-0">
                <div class="col-6 p-2">
                    ETA Offered
                </div>
                <div class="col-6 p-2" style="background-color: #f1f1f1;">
                    <font *ngIf="datapoDetail?.quotationEtaOffered">{{datapoDetail?.quotationEtaOffered | date:'d MMM y'}}</font>
                    <font *ngIf="!datapoDetail?.quotationEtaOffered">-</font>
                </div>
            </div>
        </div>

        <div class="col-12 p-1"><br></div>

        <div class="col-5">
            <table class="table p-2">
                <tr>
                    <td style="background-color: #000000;color: #ffffff;"><h5 class="p-0">
                        <!-- Vendor Company -->
                        {{datapoDetail?.vendorName}}
                    </h5></td>
                </tr>
                <tr>
                    <td style="border: solid 1px #f0f0f0;">
                       {{datapoDetail?.vendorAddress}}                    
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
            <table class="table">
                <tr>
                    <td style="background-color: #000000;color: #ffffff;"><h5 class="p-0">Shipping Detail</h5></td>
                </tr>
                <tr>
                    <td style="border: solid 1px #f0f0f0;">{{ datapoDetail?.quotationOfferedDeliveryAddress }}         
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-12"><br></div>

        <div class="col-12">
            <table class="table">
                <tr>
                    <th style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>#</b>
                    </th>
                    <th  width="140px" style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Manf. Code.</b>
                    </th>
                    <th style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Brand</b>
                    </th>
                    <th style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Product Name</b>
                    </th>
                    <th style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Product Description</b>
                    </th>
                    <th width="100px" style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>QTY Offer</b>
                    </th>
                    <th width="140px" style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Price</b>
                    </th>
                    <th width="140px" style="border: solid 1px #f0f0f0;background-color: #000000;color: #ffffff;">
                        <b>Line Total</b>
                    </th>
                </tr>


                <tr *ngFor="let product of datapoDetail?.PoItem; let i = index">
  
      
                    <td style="border: solid 1px #f0f0f0;">{{ i+1 }}</td>
                    <td style="border: solid 1px #f0f0f0;">{{ product.productManufacturerCode }}</td>
                    <td style="border: solid 1px #f0f0f0;">{{ product.productBrand }}</td>
                    <td style="border: solid 1px #f0f0f0;">{{ product.productName }}</td>
                    <td style="border: solid 1px #f0f0f0;">{{ divDetailspec(product.productDescription) }}</td>
                    <td style="border: solid 1px #f0f0f0;text-align: right;">{{ product.selectedQty | number }}</td>
                    <td style="border: solid 1px #f0f0f0;text-align: right;">{{ datapoDetail?.rfqCurrency }} {{ product.productPrice | number }}</td>
                    <td style="border: solid 1px #f0f0f0;text-align: right;">{{ datapoDetail?.rfqCurrency }} {{ product.selectedQty * product.productPrice | number }}</td>
                </tr>



            </table>
        </div>

        <div class="col-5">
            <div class="p-2" style="background-color: #f0f0f0;">
                <b>Offer memo to Customer</b>
              <br><br>

            </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5 text-end">
            <table class="table">
                <tr *ngIf="!datapoDetail?.vendorIsTaxIncluded">
                    <td style="border:0px;text-align:right;">
                        Subtotal
                    </td>
                    <td style="border:0px;text-align:right;">
                        {{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice | number}}
                    </td>
                </tr>
                <tr *ngIf="!datapoDetail?.vendorIsTaxIncluded">
                    <td style="border:0px;text-align:right;">

                        <ng-container *ngIf="datapoDetail?.vendorTaxValue">Tax</ng-container>
                        <ng-container *ngIf="datapoDetail?.vendorTaxPercentage">Tax({{datapoDetail?.vendorTaxPercentage}}%)</ng-container>

                    </td>
                    <td style="border:0px;text-align:right;">
                        
                        <ng-container *ngIf="datapoDetail?.vendorTaxValue">{{ datapoDetail?.rfqCurrency }} {{datapoDetail?.vendorTaxValue | number}}</ng-container>
                        <ng-container *ngIf="datapoDetail?.vendorTaxPercentage">{{ datapoDetail?.rfqCurrency }} {{datapoDetail?.vendorTaxPercentage * totalQuotationPrice /100 | number}}</ng-container>

                    </td>
                </tr>
                <tr>
                    <td  style="border:0px;text-align:right;" class="fw-bold" >
                        Total
                    </td>
                    <td class="fw-bold" style="border:0px;text-align:right;">
                        
                        <!-- <ng-container *ngIf="datapoDetail?.getValueTax">{{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice + getValueTax | number}}</ng-container>
                        <ng-container *ngIf="datapoDetail?.vendorTaxPercentage">{{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice + (datapoDetail?.vendorTaxPercentage * totalQuotationPrice /100) | number}}</ng-container> -->

                        <ng-container *ngIf="datapoDetail?.vendorTaxValue">
                            {{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice + datapoDetail?.vendorTaxValue | number}}
                        </ng-container>
                        <ng-container *ngIf="datapoDetail?.vendorTaxPercentage">
                            {{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice + (datapoDetail?.vendorTaxPercentage * totalQuotationPrice /100) | number}}
                        </ng-container>
                        <ng-container *ngIf="datapoDetail?.vendorIsTaxIncluded">
                            {{ datapoDetail?.rfqCurrency }} {{totalQuotationPrice | number}}
                        </ng-container>

                    </td>
                </tr>
            </table>
        </div>

        <div class="col-12">
            <br>
            <br>
            <!-- This document is submitted by (_________) on (__/__/____  __:__)
            <br> -->
            This document is approved by ({{datapoDetail?.approvedBy}})
            <br>
            <br>
            <br>
            <br><br>
        </div>
    </div>





</div>
</div>